import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/UseMediaQueries";

export const header: SxProps<Theme> = {
	display: "flex",
	justifyContent: "space-between",
	alignItems: "flex-start",
};

export const deleteButton: SxProps<Theme> = (theme) => ({
	position: "absolute",
	top: "-15px",
	left: "-15px",
	zIndex: theme.zIndex.modal,
});

export const smallWidgets: (mediaQueries: MediaQueries) => SxProps<Theme> = (mediaQueries) => (theme) => {
	return {
		...{
			position: "relative",
			overflow: "visible",
			gridColumn: "1",
			gridRowEnd: "span 2",
			width: "100%",
			maxWidth: `calc(100vw - ${theme.spacing(5)})`,
		},
		...(mediaQueries.is900 && {
			gridColumn: "span 1",
			gridRowEnd: "span 2",
		}),
		...(mediaQueries.isLg && {
			gridRowEnd: "span 1",
		}),
	};
};

export const mediumWidgets: (mediaQueries: MediaQueries) => SxProps<Theme> = (mediaQueries) => (theme) => {
	return {
		...{
			position: "relative",
			overflow: "visible",
			width: "100%",
			maxWidth: `calc(100vw - ${theme.spacing(5)})`,
			gridColumn: "1",
		},
		...(mediaQueries.is900 && {
			maxWidth: "unset",
			gridColumn: "span 1",
			gridRowEnd: "span 2",
		}),
	};
};

export const largeWidgets: (mediaQueries: MediaQueries) => SxProps<Theme> = (mediaQueries) => (theme) => {
	return {
		...{
			position: "relative",
			overflow: "visible",
			gridColumn: "1",
			gridRowEnd: "span 2",
			width: "100%",
			maxWidth: `calc(100vw - ${theme.spacing(5)})`,
		},
		...(mediaQueries.is900 && {
			gridColumnEnd: "span 2",
		}),
	};
};
