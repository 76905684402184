import React from "react";
import { ArrowForward } from "@mui/icons-material";
import { Box, Chip, Typography } from "@mui/material";
import { getNotarizationColor, getNotarizationStatus } from "helpers/atmobox";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { WidgetEntity } from "models/Widget";
import { theme } from "themes";

import ChipRightIcon from "components/elements/ChipRightIcon";

import I18n from "../../../I18n";
import SystemEnergyCostToolTip from "../SystemEnergyCostToolTip";

import * as styles from "./styles";

type IProps = {
	mediaQueries: MediaQueries;
	widget: WidgetEntity;
	titleVariant: "h5" | "h6";
	showNotarisationDialog: () => void;
	withNotarisation?: boolean;
};
type IState = {
	isShowingNotarisationDialog: boolean;
};

class Component extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
		this.state = {
			isShowingNotarisationDialog: false,
		};
		this.showNotarisation = this.showNotarisation.bind(this);
		this.closeNotarisation = this.closeNotarisation.bind(this);
	}

	public override render(): React.ReactNode {
		return (
			<Box sx={styles.headerContent}>
				<Box sx={styles.titleAndInfo}>
					<Typography variant={this.props.titleVariant}>{this.props.widget.widgetType.name}</Typography>
					{this.props.widget.widgetType.type === "system_energy_cost" && <SystemEnergyCostToolTip />}
				</Box>
				{this.props.withNotarisation &&
					(this.props.mediaQueries.is900 ? (
						<ChipRightIcon
							onClick={this.props.showNotarisationDialog}
							label={I18n.translate(`components.notarization_chip.${getNotarizationStatus(this.props.widget.data)}`)}
							color={getNotarizationColor(getNotarizationStatus(this.props.widget.data))}
							icon={<ArrowForward />}
							size="small"
							sx={{ cursor: "pointer", marginLeft: theme.spacing(3), marginTop: theme.spacing(1) }}
						/>
					) : (
						<Chip
							onClick={this.props.showNotarisationDialog}
							label={I18n.translate(`components.notarization_chip.${getNotarizationStatus(this.props.widget.data)}`)}
							color={getNotarizationColor(getNotarizationStatus(this.props.widget.data))}
							size="small"
							sx={{ cursor: "pointer", marginLeft: theme.spacing(2), marginTop: theme.spacing(1) }}
						/>
					))}
			</Box>
		);
	}

	private showNotarisation() {
		this.setState({
			isShowingNotarisationDialog: true,
		});
	}

	private closeNotarisation() {
		this.setState({
			isShowingNotarisationDialog: false,
		});
	}
}

export default function WidgetHeader(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}
