import React, { Component } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Box, Button, Container, Typography } from "@mui/material";
import FileApi, { DownloadFileInfo } from "api/File";
import FileDownloader from "helpers/fileDownloader";
import { DCFile } from "models/Atmobox";

import I18n from "components/materials/I18n";

import * as styles from "./styles";

type IProps = {
	missingData: DCFile[];
};
type IState = Record<string, never>;

export default class MissingData extends Component<IProps, IState> {
	public override render() {
		return (
			this.props.missingData.length && (
				<Container sx={styles.missingData}>
					<Box sx={styles.warning}>
						<WarningAmberOutlinedIcon color="warning" />
						<Typography variant="body2" ml={2}>
							<I18n map="components.missing_data.text" />
						</Typography>
					</Box>
					<Button
						variant="text"
						color="info"
						startIcon={<FileDownloadOutlinedIcon />}
						onClick={this.downloadFiles.bind(this)}>
						<Typography variant="body2" ml={2}>
							<I18n map="components.missing_data.button" />
						</Typography>
					</Button>
				</Container>
			)
		);
	}

	private async downloadFiles() {
		const blob = await FileApi.getInstance().downloadFiles(this.getListOfDCFileDownloadInfo(), true);

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		FileDownloader.download(blob, "missing_data_files.zip");
	}

	private getListOfDCFileDownloadInfo(): DownloadFileInfo[] {
		const downloadFileInfoList: DownloadFileInfo[] = this.props.missingData.reduce((acc, dcf) => {
			if (!dcf.fileMeta) {
				return acc;
			}
			return [...acc, { id: dcf.id, type: "DriftCorrected" }];
		}, [] as DownloadFileInfo[]);

		return downloadFileInfoList;
	}
}
