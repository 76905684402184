import { useNavigate } from "react-router-dom";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import UserStore from "stores/UserStore";

export default function GoogleLoginButton() {
	const navigate = useNavigate();
	const userStore = UserStore.getInstance();

	async function handleLoginSuccess(response: CredentialResponse) {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		await userStore.signInOAuth(response.credential!);
		navigate("/dashboards");
	}

	return <GoogleLogin onSuccess={handleLoginSuccess} text="signin_with" locale="en" />;
}
