import { SxProps, Theme } from "@mui/material";

export const header: SxProps<Theme> = {
	minWidth: "100%",
	margin: "0px",
};

export const getPadding: SxProps<Theme> = (theme) => {
	return {
		paddingBottom: theme.spacing(4),
	};
};

export const atmoboxInfo: SxProps<Theme> = (theme) => {
	return {
		marginTop: theme.spacing(4),
		padding: theme.spacing(3),
		background: theme.palette.grey[50],
		borderRadius: "4px",
	};
};

export const atmoboxRow: SxProps<Theme> = (theme) => {
	return {
		display: "flex",
		flexDirection: "row",
		paddingBottom: theme.spacing(2),
		justifyContent: "space-between",
		alignItems: "center",
	};
};

export const atmoNameAndNotarisation: SxProps<Theme> = (theme) => {
	return {
		display: "flex",
		flexDirection: "row",

		"> :last-child": {
			marginLeft: theme.spacing(3),
			marginTop: theme.spacing(1),
		},
	};
};

export const row: SxProps<Theme> = {
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
};

export const dataQuality: SxProps<Theme> = {
	fontStyle: "normal",
	fontWeight: 500,

	lineHeight: "166%",
	letterSpacing: "0.4px",
	color: "rgba(0, 0, 0, 0.6)",
};

export const atmoboxLocation: SxProps<Theme> = {
	display: "flex",
	flexDirection: "column",
	alignItems: "left",
};

export const data: SxProps<Theme> = {
	fontSize: "16px",
	color: "rgba(0, 0, 0, 0.6)",
};

export const tooltipInfo: SxProps<Theme> = {
	display: "flex",
	flexDirection: "column",
	alignItems: "left",
	backgroundColor: "white",
	width: "400px",
};
