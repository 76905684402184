import { Blob } from "buffer";
import Config from "configs/Config";
import { JwtPair } from "models/AuthFactory/accessToken";
import JwtStore from "stores/JwtStore";

export enum ContentType {
	JSON = "application/json",
	FORM_DATA = "multipart/form-data;",
}

export default abstract class BaseApiService {
	private readonly jwtStore = JwtStore.getInstance();
	protected readonly config = Config.getInstance();
	protected readonly rootUrl = Config.getInstance().get().api.rootUrl;
	protected readonly authFactoryUrl = Config.getInstance().get().api.authFactory;

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected constructor() {}

	protected buildHeaders(contentType: ContentType) {
		const headers = new Headers();

		if (contentType === ContentType.JSON) {
			headers.set("Content-Type", contentType);
		}

		const token = this.jwtStore.getAccessToken();

		if (token) {
			headers.set("auth-token", token);
		}

		return headers;
	}

	protected buildBody(body: { [key: string]: unknown }): string {
		return JSON.stringify(body);
	}

	protected async getRequest<T>(url: URL) {
		const request = async () =>
			await fetch(url, {
				method: "GET",
				headers: this.buildHeaders(ContentType.JSON),
			});

		return this.sendRequest<T>(request);
	}

	protected async postRequest<T>(url: URL, body: { [key: string]: unknown } = {}) {
		const request = async () =>
			await fetch(url, {
				method: "POST",
				headers: this.buildHeaders(ContentType.JSON),
				body: this.buildBody(body),
			});

		return this.sendRequest<T>(request);
	}

	protected async putRequest<T>(url: URL, body: { [key: string]: unknown } = {}) {
		const request = async () =>
			await fetch(url, {
				method: "PUT",
				headers: this.buildHeaders(ContentType.JSON),
				body: this.buildBody(body),
			});

		return this.sendRequest<T>(request);
	}

	protected async patchRequest<T>(url: URL, body: { [key: string]: unknown } = {}) {
		const request = async () =>
			await fetch(url, {
				method: "PATCH",
				headers: this.buildHeaders(ContentType.JSON),
				body: this.buildBody(body),
			});

		return this.sendRequest<T>(request);
	}

	protected async deleteRequest<T>(url: URL, body: { [key: string]: unknown } = {}) {
		const request = async () =>
			await fetch(url, {
				method: "DELETE",
				headers: this.buildHeaders(ContentType.JSON),
				body: this.buildBody(body),
			});

		return this.sendRequest<T>(request);
	}

	protected async postDownload(url: URL, body: { [key: string]: unknown } = {}) {
		const request = async () =>
			await fetch(url, {
				method: "POST",
				headers: this.buildHeaders(ContentType.JSON),
				body: this.buildBody(body),
			});
		const response = await request();
		return this.processBlobResponse(response, request);
	}

	protected async getDownload(url: URL) {
		const request = async () =>
			await fetch(url, {
				method: "GET",
				headers: this.buildHeaders(ContentType.JSON),
			});
		const response = await request();
		return this.processBlobResponse(response, request);
	}

	private async sendRequest<T>(request: () => Promise<Response>): Promise<T> {
		const response = await request();
		return this.processResponse<T>(response, request);
	}

	protected async processResponse<T>(response: Response, request: () => Promise<Response>): Promise<T> {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let responseJson: any | null;
		try {
			responseJson = await response.json();
		} catch (err: unknown) {
			responseJson = null;
		}

		if (responseJson?.error?.message === "TOKEN_EXPIRED" || responseJson?.error?.status === 401) {
			try {
				await this.refreshToken();

				const retryRequestResponse = await request();

				if (!retryRequestResponse.ok) {
					return Promise.reject(retryRequestResponse);
				}

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				let retryRequestResponseJson: any | null;
				try {
					retryRequestResponseJson = await retryRequestResponse.json();
				} catch (err: unknown) {
					retryRequestResponseJson = null;
				}

				return retryRequestResponseJson as T;
			} catch (err: unknown) {
				await this.basePresign();
			}
		} else if (response.status === 401) {
			await this.basePresign();
		}

		if (!response.ok) {
			return Promise.reject(response);
		}

		return responseJson as T;
	}

	protected onError(error: unknown) {
		console.error(error);
	}

	private async refreshToken() {
		const response = await fetch(
			this.authFactoryUrl.concat("/app-auth/").concat(this.config.get().app).concat("/refresh-token"),
			{
				method: "POST",
				headers: this.buildHeaders(ContentType.JSON),
				body: this.buildBody({
					accessToken: this.jwtStore.getAccessToken(),
					refreshToken: this.jwtStore.getRefreshToken(),
				}),
			},
		);

		if (!response.ok) {
			return Promise.reject(response);
		}

		const { jwtPair } = (await response.json()) as { jwtPair: JwtPair };
		this.jwtStore.setJwtPair(jwtPair);
		return;
	}

	private async basePresign() {
		const response = await fetch(
			this.authFactoryUrl.concat("/app-auth/").concat(this.config.get().app).concat("/presign"),
			{
				method: "POST",
				headers: this.buildHeaders(ContentType.JSON),
			},
		);

		if (!response.ok) {
			return Promise.reject(response);
		}

		const { jwtPair } = (await response.json()) as { jwtPair: JwtPair };
		this.jwtStore.setJwtPair(jwtPair);
		return;
	}

	protected async processBlobResponse(response: Response, request: () => Promise<Response>): Promise<Blob> {
		let responseBlob: Blob | null;
		try {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			responseBlob = await response.blob();
		} catch (err: unknown) {
			responseBlob = null;
		}

		if (response.status === 401) {
			try {
				await this.refreshToken();

				const retryRequestResponse = await request();

				if (!retryRequestResponse.ok) {
					return Promise.reject(retryRequestResponse);
				}

				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return await retryRequestResponse.blob();
			} catch (err: unknown) {
				await this.basePresign();
			}
		}

		if (!response.ok || !responseBlob) {
			return Promise.reject(response);
		}

		return responseBlob;
	}
}

export interface IResponse {
	http_status: number;
}
