import BaseApiService from "api/BaseApiService";
import { Blob } from "buffer";
import Config from "configs/Config";
import { EntityId } from "models";
import { FileMeta } from "models/Atmobox/index";

export type DownloadFileInfo = { id: EntityId; type: FileMeta["type"] };

export default class FileApi extends BaseApiService {
	private static instance: FileApi;
	private readonly baseUrl = this.rootUrl.concat(Config.getInstance().get().app).concat("/app-file-metas");

	public static getInstance(): FileApi {
		return (this.instance = this.instance ?? new FileApi());
	}

	public async downloadFiles(files: DownloadFileInfo[], withDependencies: boolean): Promise<Blob> {
		const url = new URL(this.baseUrl.concat(withDependencies ? "?dependencies=true" : "?dependencies=false"));

		try {
			if (!url) {
				throw new Error("404");
			}
			return await this.postDownload(url, { files });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
