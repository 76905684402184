import React from "react";
import { Add, ArrowForward } from "@mui/icons-material";
import { Box, Button, Card, CardContent, IconButton, SxProps, Theme, Typography } from "@mui/material";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import {
	AtmoboxesMapProps,
	Co2AverageProps,
	Co2EvolutionProps,
	Co2RelativeAverageProps,
	SystemEnergyCostProps,
	WidgetEntity,
	WidgetType,
} from "models/Widget";
import SelectedDashboardStore from "stores/SelectedDashboardStore";

import I18n from "components/materials/I18n";
import WidgetHeader from "components/materials/widgets/materials/WidgetHeader";
import Co2EvolutionView from "components/materials/widgets/views/Co2EvolutionView";
import Co2RelativeAverageView from "components/materials/widgets/views/Co2RelativeAverageView";
import SystemEnergyCostView from "components/materials/widgets/views/SystemEnergyCostView";

import AtmoboxesMapView from "../../views/AtmoboxesMapView";
import Co2AverageView from "../../views/Co2AverageView";

import * as styles from "./styles";

type IProps = {
	mediaQueries: MediaQueries;
	widget: WidgetEntity;
	onClick: () => void;
};
type IState = {
	isHovering: boolean;
};

type WidgetComponent =
	| ReturnType<typeof Co2RelativeAverageView>
	| ReturnType<typeof Co2EvolutionView>
	| ReturnType<typeof AtmoboxesMapView>
	| ReturnType<typeof SystemEnergyCostView>
	| ReturnType<typeof Co2AverageView>;

class Component extends React.Component<IProps, IState> {
	private readonly selectedDashboardStore = SelectedDashboardStore.getInstance();
	public constructor(props: IProps) {
		super(props);
		this.state = {
			isHovering: false,
		};
		this.addWidget = this.addWidget.bind(this);
		this.setIsHoveringTrue = this.setIsHoveringTrue.bind(this);
		this.setIsHoveringFalse = this.setIsHoveringFalse.bind(this);
	}

	public override render() {
		return (
			<Card sx={this.getWidgetStyle()}>
				<Box sx={styles.foreground} onMouseOver={this.setIsHoveringTrue} onMouseOut={this.setIsHoveringFalse}>
					{this.state.isHovering && (
						<Button variant="contained" onClick={this.addWidget} startIcon={<Add />}>
							<I18n map="components.dashboard.add_widget_button" />
						</Button>
					)}
				</Box>
				<CardContent>
					<Box sx={styles.header(this.props.mediaQueries)}>
						<WidgetHeader titleVariant="h6" widget={this.props.widget} showNotarisationDialog={() => {}} />
						{this.props.mediaQueries.isMd && (
							<Button>
								<Typography variant="body2" mr={2}>
									<I18n map="components.widget.view_more_button" />
								</Typography>
								<ArrowForward fontSize="small" />
							</Button>
						)}
						{!this.props.mediaQueries.isMd && (
							<IconButton size="small">
								<ArrowForward color="primary" fontSize="small" />
							</IconButton>
						)}
					</Box>
					{this.getWidgetComponent(this.props.widget)}
				</CardContent>
			</Card>
		);
	}

	private getWidgetStyle(): SxProps<Theme> {
		const widgetStyles: Record<WidgetType, SxProps<Theme>> = {
			co2_average: styles.averageWidget,
			system_energy_cost: styles.energyCostWidget,
			co2_relative_average: styles.relativeAverageWidget,
			atmoboxes_map: styles.atmoboxMapWidget,
			co2_evolution: styles.evolutionWidget,
		};

		return widgetStyles[this.props.widget.widgetType.type];
	}

	private async addWidget() {
		await this.selectedDashboardStore.addWidget(this.props.widget.id);
		this.props.onClick();
	}

	private setIsHoveringTrue() {
		this.setState({ isHovering: true });
	}

	private setIsHoveringFalse() {
		this.setState({ isHovering: false });
	}

	private getWidgetComponent(widget: WidgetEntity): WidgetComponent {
		const components: Record<WidgetType, WidgetComponent> = {
			co2_relative_average: (
				<Co2RelativeAverageView narrowedWidget={{ ...widget, props: widget.props as Co2RelativeAverageProps }} />
			),
			atmoboxes_map: <AtmoboxesMapView narrowedWidget={{ ...widget, props: widget.props as AtmoboxesMapProps }} />,
			co2_average: <Co2AverageView narrowedWidget={{ ...widget, props: widget.props as Co2AverageProps }} />,
			co2_evolution: <Co2EvolutionView narrowedWidget={{ ...widget, props: widget.props as Co2EvolutionProps }} />,
			system_energy_cost: (
				<SystemEnergyCostView narrowedWidget={{ ...widget, props: widget.props as SystemEnergyCostProps }} />
			),
		};

		return components[widget.widgetType.type];
	}
}

export default function WidgetToAdd(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}
