import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/UseMediaQueries";

export const averageWidget: SxProps<Theme> = {
	position: "relative",
	gridColum: "1",
	gridRow: "1",
};

export const energyCostWidget: SxProps<Theme> = {
	position: "relative",
	gridColumn: "1",
	gridRow: "2",
};

export const atmoboxMapWidget: SxProps<Theme> = {
	position: "relative",
	gridColumn: "2",
	gridRow: "1 / span 2",
};

export const relativeAverageWidget: SxProps<Theme> = {
	position: "relative",
	gridColumn: "1",
	gridRow: "5 / span 2",
};

export const evolutionWidget: SxProps<Theme> = {
	position: "relative",
	gridColumn: "1 / 3",
	gridRow: "3 / span 2",
};

export const foreground: SxProps<Theme> = (theme) => {
	return {
		position: "absolute",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
		cursor: "pointer",
		":hover": {
			backgroundColor: theme.palette.primary.light,
			backdropFilter: "blur(2px)",
		},
		zIndex: theme.zIndex.fab,
	};
};

export const header = (mediaQueries: MediaQueries): SxProps<Theme> => {
	return {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "flex-start",
		...(mediaQueries.isMd && {
			alignItems: "center",
		}),
	};
};
