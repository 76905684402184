import React from "react";
import { Button, Dialog, DialogActions, DialogContent, SxProps, Theme, Typography } from "@mui/material";
import WidgetApi from "api/WidgetApi";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import {
	AtmoboxesMapProps,
	Co2AverageProps,
	Co2EvolutionProps,
	Co2RelativeAverageProps,
	SystemEnergyCostProps,
	WidgetEntity,
	WidgetType,
} from "models/Widget";
import SelectedDashboardStore from "stores/SelectedDashboardStore";

import DialogHeader from "components/elements/DialogHeader";
import Toast, { ToastFeedback } from "components/elements/Toast";
import I18n from "components/materials/I18n";
import Co2EvolutionDetails from "components/materials/widgets/details/Co2EvolutionDetails";
import Co2RelativeAverageDetails from "components/materials/widgets/details/Co2RelativeAverageDetails";

import AtmoboxesMapDetails from "../../details/AtmoboxesMapDetails";
import Co2AverageDetails from "../../details/Co2AverageDetails";
import SystemEnergyCostDetails from "../../details/SystemEnergyCostDetails";
import WidgetHeader from "../WidgetHeader";

import * as styles from "./styles";

type IProps = {
	mediaQueries: MediaQueries;
	open: boolean;
	close: () => void;
	widget: WidgetEntity;
	withNotarisation: boolean;
	showNotarisation: (widgetPreview: WidgetEntity) => void;
};
type IState = {
	feedback?: ToastFeedback;
	widgetPreview: WidgetEntity;
};

type WidgetComponent =
	| ReturnType<typeof Co2RelativeAverageDetails>
	| ReturnType<typeof Co2EvolutionDetails>
	| ReturnType<typeof Co2AverageDetails>
	| ReturnType<typeof AtmoboxesMapDetails>
	| ReturnType<typeof SystemEnergyCostDetails>;

class Component extends React.Component<IProps, IState> {
	private readonly selectedDashboardStore = SelectedDashboardStore.getInstance();
	private widgetProps: WidgetEntity["props"];

	public constructor(props: IProps) {
		super(props);
		this.widgetProps = { ...this.props.widget.props };
		this.state = {
			feedback: undefined,
			widgetPreview: this.props.widget,
		};
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<Dialog maxWidth={this.getDialogWidth()} sx={styles.root} open={this.props.open} onClose={this.props.close}>
					<DialogHeader close={this.props.close}>
						<WidgetHeader
							titleVariant="h5"
							widget={this.state.widgetPreview}
							showNotarisationDialog={() => this.props.showNotarisation(this.state.widgetPreview)}
							withNotarisation={this.props.withNotarisation}
						/>
					</DialogHeader>

					<DialogContent sx={this.getStyleOfComponent()}>
						{this.getWidgetComponent(this.state.widgetPreview)}
					</DialogContent>

					<DialogActions>
						<Button variant="outlined" color="info" onClick={this.props.close}>
							<Typography variant="body2" ml={2}>
								<I18n map="general_text.cancel" />
							</Typography>
						</Button>
						<Button variant="contained" onClick={this.saveWidget.bind(this)}>
							<Typography variant="body2">
								<I18n map="general_text.save" />
							</Typography>
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}

	private getWidgetComponent(widget: WidgetEntity): WidgetComponent {
		const components: Record<WidgetType, WidgetComponent> = {
			co2_relative_average: (
				<Co2RelativeAverageDetails
					narrowedWidget={{ ...widget, props: widget.props as Co2RelativeAverageProps }}
					setWidgetProps={this.setWidgetProps.bind(this)}
				/>
			),
			atmoboxes_map: (
				<AtmoboxesMapDetails
					narrowedWidget={{ ...widget, props: widget.props as AtmoboxesMapProps }}
					setWidgetProps={this.setWidgetProps.bind(this)}
				/>
			),
			co2_average: (
				<Co2AverageDetails
					narrowedWidget={{ ...widget, props: widget.props as Co2AverageProps }}
					setWidgetProps={this.setWidgetProps.bind(this)}
					previewWidget={this.previewWidget.bind(this)}
				/>
			),
			system_energy_cost: (
				<SystemEnergyCostDetails
					narrowedWidget={{ ...widget, props: widget.props as SystemEnergyCostProps }}
					setWidgetProps={this.setWidgetProps.bind(this)}
				/>
			),
			co2_evolution: (
				<Co2EvolutionDetails
					narrowedWidget={{ ...widget, props: widget.props as Co2EvolutionProps }}
					setWidgetProps={this.setWidgetProps.bind(this)}
					previewWidget={this.previewWidget.bind(this)}
				/>
			),
		};

		return components[widget.widgetType.type];
	}

	public override componentDidUpdate(prevProps: Readonly<IProps>): void {
		if (prevProps.widget === this.props.widget) {
			return;
		}
		this.setState({
			widgetPreview: this.props.widget,
		});
	}

	private async saveWidget() {
		try {
			await this.selectedDashboardStore.updateWidget(this.state.widgetPreview.id, this.widgetProps);
		} catch (err: unknown) {
			this.setState({ feedback: { message: I18n.translate("error_messages.save_widget"), severity: "error" } });
		}
	}

	public async setWidgetProps(widgetPropsUpdates: Partial<WidgetEntity["props"]>) {
		this.widgetProps = {
			...this.widgetProps,
			...widgetPropsUpdates,
		};
	}

	public async previewWidget(widgetPropsUpdates: Partial<WidgetEntity["props"]>) {
		const widgetPreview: WidgetEntity = {
			...this.state.widgetPreview,
			props: { ...this.state.widgetPreview.props, ...widgetPropsUpdates },
		};

		try {
			const updatedWidgetPreview = await WidgetApi.getInstance().previewWidget(widgetPreview.id, widgetPreview.props);
			this.setState({
				widgetPreview: updatedWidgetPreview,
			});
		} catch (err: unknown) {
			this.setState({ feedback: { message: I18n.translate("error_messages.preview_widget"), severity: "error" } });
		}
	}

	private getDialogWidth() {
		type WidgetWidth = "xl";
		const dialogWith: Partial<Record<WidgetType, WidgetWidth>> = {
			co2_evolution: "xl",
		};

		return dialogWith[this.state.widgetPreview.widgetType.type] || "md";
	}

	private getStyleOfComponent(): SxProps<Theme> {
		switch (this.state.widgetPreview.widgetType.type) {
			case "co2_relative_average":
				return styles.detailsOfCO2RelativeAverage(this.props.mediaQueries);
				break;
			case "co2_evolution":
				return styles.detailsOfCO2Evolution(this.props.mediaQueries);
				break;
		}
		return styles.detailsOfCO2RelativeAverage(this.props.mediaQueries);
	}
}

export default function DialogWidgetDetails(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}
