import React from "react";
import { Box, Divider, Theme, useTheme } from "@mui/material";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { SystemEnergyCostProps, WidgetEntity } from "models/Widget";

import SystemEnergyCostGraph from "../../content/SystemEnergyEnergyCostGraph";
import DateOfData from "../../materials/DateOfData";

import * as styles from "./styles";

type IProps = {
	theme: Theme;
	mediaQueries: MediaQueries;
	narrowedWidget: WidgetEntity & {
		props: SystemEnergyCostProps;
	};
};

type IState = Record<string, never>;

class Component extends React.Component<IProps, IState> {
	public override render() {
		return (
			<Box sx={styles.root(this.props.mediaQueries)}>
				<SystemEnergyCostGraph narrowedWidget={this.props.narrowedWidget} noInfo />
				<Divider orientation={this.props.mediaQueries.isLg ? "vertical" : "horizontal"} variant="middle" />
				<Box sx={styles.date(this.props.mediaQueries)}>
					<DateOfData
						fromDate={new Date(this.props.narrowedWidget.props.from)}
						toDate={new Date(this.props.narrowedWidget.props.to)}
					/>
				</Box>
			</Box>
		);
	}
}

export default function SystemEnergyCostView(props: Omit<IProps, "mediaQueries" | "theme">) {
	const theme = useTheme();
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, theme, mediaQueries }} />;
}
