import React from "react";
import { Box, Typography } from "@mui/material";
import { capitalizeFirstLetter } from "helpers/text";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { Co2RelativeAverageProps, WidgetEntity } from "models/Widget";

import I18n from "components/materials/I18n";
import DateTimeOfData from "components/materials/widgets/materials/DateTimeOfData";

import CO2RelativeAverageGraph from "../../content/Co2RelativeAverageGraph";

import * as styles from "./styles";

type IProps = {
	mediaQueries: MediaQueries;
	narrowedWidget: WidgetEntity & {
		props: Co2RelativeAverageProps;
	};
};

type IState = {
	options: ApexCharts.ApexOptions | undefined;
	series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
};

class Component extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
	}

	public override render() {
		const atmoboxes = this.props.narrowedWidget.data;
		const targetAtmoboxId = this.props.narrowedWidget.props.atmoboxId;
		const selectedAtmobox = atmoboxes.find((atmobox) => atmobox.id === targetAtmoboxId) || null;
		const fromDate = new Date(this.props.narrowedWidget.props.from);
		const toDate = new Date(this.props.narrowedWidget.props.to);

		if (!atmoboxes.length || !selectedAtmobox) {
			return (
				<Typography variant="body2">
					<I18n map="general_text.no_data" />
				</Typography>
			);
		}

		return (
			<Box style={{ maxHeight: "390px" }}>
				<Typography variant="body2" mr={2} sx={styles.atmoName}>
					{capitalizeFirstLetter(selectedAtmobox?.name ?? I18n.translate("general_text.no_data"))}
				</Typography>
				<DateTimeOfData fromDate={fromDate} toDate={toDate} />
				<CO2RelativeAverageGraph narrowedWidget={this.props.narrowedWidget} />
			</Box>
		);
	}
}

export default function Co2RelativeAverageView(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}
