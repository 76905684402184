import React, { Component } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

type IProps = {
	noMinutes?: true;
	setFromDate: (newFromDate: Dayjs | null) => void;
	setToDate: (newToDate: Dayjs | null) => void;
	fromDate: Dayjs;
	toDate: Dayjs;
};

type IState = {
	fromDate: Dayjs | undefined;
	toDate: Dayjs | undefined;
};

export default class RangeDateTimePicker extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			fromDate: this.props.fromDate,
			toDate: this.props.toDate,
		};
	}

	override render() {
		return (
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Stack direction="row" spacing={3} style={{ minWidth: "100%" }}>
					<MobileDateTimePicker
						minutesStep={this.props.noMinutes ? 60 : 1}
						inputFormat={"DD/MM/YYYY - HH:mm"}
						ampm={false}
						value={this.state.fromDate}
						label="From"
						onChange={this.handleFromChange.bind(this)}
						renderInput={(params) => (
							<TextField
								size="medium"
								{...params}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton edge="end">
												<CalendarTodayIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						)}
					/>
					<MobileDateTimePicker
						minutesStep={this.props.noMinutes ? 60 : 1}
						minDateTime={this.state.fromDate}
						inputFormat={"DD/MM/YYYY - HH:mm"}
						ampm={false}
						value={this.state.toDate}
						label="To"
						onChange={this.handleToChange.bind(this)}
						renderInput={(params) => (
							<TextField
								size="medium"
								{...params}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton edge="end">
												<CalendarTodayIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						)}
					/>
				</Stack>
			</LocalizationProvider>
		);
	}

	private handleFromChange = (newDate: Dayjs | null): void => {
		this.setState({ fromDate: newDate ?? undefined });
		this.props.setFromDate(newDate);
	};

	private handleToChange = (newDate: Dayjs | null): void => {
		this.setState({ toDate: newDate ?? undefined });
		this.props.setToDate(newDate);
	};
}
