import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/UseMediaQueries";
import { sideMenuWidth } from "themes/constants";

export const root: (mediaQueries: MediaQueries) => SxProps<Theme> = (mediaQueries) => {
	return {
		...{
			display: "flex",
			flexDirection: "column",
		},
		...(mediaQueries.isMd && {
			flexDirection: "row",
			alignItems: "center",
		}),
	};
};

export const iconWrapper: (mediaQueries: MediaQueries) => SxProps<Theme> = (mediaQueries) => (theme) => {
	return {
		...{
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			padding: theme.spacing(2, 3),
			backgroundColor: theme.palette.background.paper,
			width: "100%",
		},
		...(mediaQueries.isMd && {
			width: sideMenuWidth,
			padding: theme.spacing(3),
		}),
	};
};

export const mainContent: (mediaQueries: MediaQueries) => SxProps<Theme> = (mediaQueries) => (theme) => {
	return {
		...{
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			flex: 1,
			height: "100%",
			padding: theme.spacing(3),
		},
		...(mediaQueries.isMd && {
			padding: theme.spacing(0, 3),
		}),
	};
};

export const buttons: SxProps<Theme> = {
	display: "flex",
	alignItems: "center",
};

export const firstButton: SxProps<Theme> = (theme) => {
	return {
		marginRight: theme.spacing(2),
	};
};

export const editWidgetsButton: (isVisible: boolean) => SxProps<Theme> = (isVisible) => (theme) => {
	return {
		...firstButton(theme),
		visibility: isVisible ? "inherit" : "hidden",
	};
};

export const widgetsEditionButtons: SxProps<Theme> = (theme) => {
	return {
		...buttons,
		zIndex: theme.zIndex.modal,
	};
};

export const menu: SxProps<Theme> = {
	">div:nth-of-type(3)": {
		width: sideMenuWidth,

		right: 0,
		left: "unset!important",
		boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		borderRadius: 0,
	},
};

export const background: SxProps<Theme> = (theme) => ({
	position: "fixed",
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	zIndex: theme.zIndex.fab,
});
