import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";

import * as styles from "./styles";

type IProps = {
	fromDate: Date;
	toDate: Date;
};
type IState = Record<string, never>;

export default class DateOfData extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
	}

	public override render(): React.ReactNode {
		return (
			<Box sx={styles.dateContainer}>
				<Box sx={styles.fromContent}>
					<Typography sx={styles.dateInfo}>From</Typography>
					<Box sx={styles.date}>
						<Typography>{format(this.props.fromDate, "dd/MM/yyyy")}</Typography>
						<ArrowForwardIcon sx={styles.arrowIcon} />
					</Box>
				</Box>
				<Box sx={styles.toContent}>
					<Typography sx={styles.dateInfo}>To</Typography>
					<Box sx={styles.date}>
						<Typography>{format(this.props.toDate, "dd/MM/yyyy")}</Typography>
					</Box>
				</Box>
			</Box>
		);
	}
}
