import React from "react";
import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import WidgetApi from "api/WidgetApi";
import dayjs, { Dayjs } from "dayjs";
import { capitalizeFirstLetter } from "helpers/text";
import useMediaQueries from "hooks/UseMediaQueries";
import { Atmobox } from "models/Atmobox";
import { Co2RelativeAverageProps, WidgetEntity } from "models/Widget";
import DashboardsStore from "stores/DashboardsStore";

import RangeDateTimePicker from "components/elements/RangeDateTimePicker";
import Toast, { ToastFeedback } from "components/elements/Toast";
import I18n from "components/materials/I18n";

import CO2RelativeAverageGraph from "../../content/Co2RelativeAverageGraph";
import WidgetBottomDetails from "../../materials/WidgetBottomDetails";

import * as styles from "./styles";

type IProps = {
	narrowedWidget: WidgetEntity & {
		props: Co2RelativeAverageProps;
	};
	setWidgetProps: (widgetProps: WidgetEntity["props"]) => void;
};
type IState = {
	widgetPreview: WidgetEntity & {
		props: Co2RelativeAverageProps;
	};
	selectedAtmobox?: Atmobox;
	feedback?: ToastFeedback;
};

class Component extends React.Component<IProps, IState> {
	private fromDate: Date;
	private toDate: Date;

	public constructor(props: IProps) {
		super(props);

		const selectedAtmobox = this.props.narrowedWidget.data.find(
			(atmobox) => atmobox.id === this.props.narrowedWidget.props.atmoboxId,
		);
		this.state = {
			widgetPreview: this.props.narrowedWidget,
			selectedAtmobox: selectedAtmobox,
			feedback: undefined,
		};

		this.fromDate = this.state.widgetPreview.props.from;
		this.toDate = this.state.widgetPreview.props.to;
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<Autocomplete
					sx={styles.getPadding}
					id={`autocomplete-widget-${this.state.widgetPreview.widgetType.name}-${this.state.widgetPreview.id}`}
					onChange={(_, value) => {
						this.setSelectedAtmobox(value);
					}}
					getOptionLabel={(option) => capitalizeFirstLetter(option.name)}
					options={DashboardsStore.getInstance()
						.getAtmoboxes()
						.sort((a, b) => a.name.localeCompare(b.name))}
					value={this.state.selectedAtmobox}
					renderInput={(params) => (
						<TextField {...params} label={I18n.translate("components.widget.autocomplete.single")} />
					)}
					isOptionEqualToValue={(option, value) => option.id === value.id}
				/>

				<Box sx={styles.getPadding}>
					<RangeDateTimePicker
						noMinutes
						setFromDate={this.setFromDate}
						setToDate={this.setToDate}
						fromDate={dayjs(this.state.widgetPreview.props.from)}
						toDate={dayjs(this.state.widgetPreview.props.to)}
					/>
				</Box>
				<Button variant="contained" onClick={this.previewWidget.bind(this)}>
					<Typography variant="body2">
						<I18n map="general_text.validate" />
					</Typography>
				</Button>
				<CO2RelativeAverageGraph narrowedWidget={this.state.widgetPreview} />
				<WidgetBottomDetails narrowedWidget={this.state.widgetPreview} />
			</>
		);
	}

	public override componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
		if (this.state.widgetPreview !== prevState.widgetPreview) {
			const selectedAtmoboxId = this.state.widgetPreview.props.atmoboxId;
			const selectedAtmobox = this.state.widgetPreview.data.find((atmobox) => atmobox.id === selectedAtmoboxId);
			if (!selectedAtmobox) {
				return;
			}

			this.setState({
				selectedAtmobox: selectedAtmobox,
			});
			this.fromDate = this.state.widgetPreview.props.from;
			this.toDate = this.state.widgetPreview.props.to;
		}

		if (this.props.narrowedWidget !== prevProps.narrowedWidget) {
			this.setState({
				widgetPreview: this.props.narrowedWidget,
			});
		}
	}

	private setFromDate = (newFromDate: Dayjs | null): void => {
		if (!newFromDate) {
			return;
		}
		this.fromDate = new Date(newFromDate.toString());
		this.props.setWidgetProps({
			from: this.fromDate,
		} as Co2RelativeAverageProps);
	};

	private setToDate = (newToDate: Dayjs | null): void => {
		if (!newToDate) {
			return;
		}
		this.toDate = new Date(newToDate.toString());
		this.props.setWidgetProps({
			to: this.toDate,
		} as Co2RelativeAverageProps);
	};

	private async previewWidget() {
		if (!this.state.selectedAtmobox) {
			return;
		}
		const widgetProps = {
			atmoboxId: this.state.selectedAtmobox.id,
			from: this.fromDate,
			to: this.toDate,
		};

		try {
			const widgetPreview = await WidgetApi.getInstance().previewWidget(this.state.widgetPreview.id, widgetProps);
			this.setState({
				widgetPreview: { ...widgetPreview, props: widgetPreview.props as Co2RelativeAverageProps },
			});
		} catch (err: unknown) {
			this.setState({ feedback: { message: I18n.translate("error_messages.preview_widget"), severity: "error" } });
		}

		this.props.setWidgetProps(widgetProps);
	}

	private setSelectedAtmobox(atmobox: Atmobox | null): void {
		if (!atmobox) {
			return;
		}
		this.setState({ selectedAtmobox: atmobox });
		this.props.setWidgetProps({
			atmoboxId: atmobox.id,
		} as Co2RelativeAverageProps);
	}
}

export default function Co2RelativeAverageDetails(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}
