import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import {
	Box,
	Button,
	Chip,
	Container,
	IconButton,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import FileApi from "api/File";
import { getNotarizationColor } from "helpers/atmobox";
import FileDownloader from "helpers/fileDownloader";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { AtmoboxFile } from "models/Atmobox";
import { theme } from "themes";

import I18n from "components/materials/I18n";

import * as styles from "./styles";

type IProps = {
	files: AtmoboxFile[];
	mediaQueries: MediaQueries;
};

type TabOrder = "desc" | "asc";

type IState = {
	tabOrder: TabOrder;
	rows: AtmoboxFile[];
};

class Component extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
		this.state = {
			tabOrder: "asc",
			rows: props.files,
		};
	}

	public override render(): React.ReactNode {
		if (this.props.mediaQueries.isSm) {
			return (
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell sx={{ fontWeight: "bold" }} sortDirection={this.state.tabOrder}>
									<TableSortLabel active={true} direction={this.state.tabOrder} onClick={() => this.sortTable()}>
										<I18n map="components.notarization_dialog.table.filename" />
									</TableSortLabel>
								</TableCell>

								<TableCell />

								<TableCell sx={styles.headCellsStyle} align="center">
									<Box sx={styles.cellContent}>
										<I18n map="components.notarization_dialog.table.notarization_status" />
									</Box>
								</TableCell>

								<TableCell sx={styles.headCellsStyle} align="center">
									<Box sx={styles.cellContent}>
										<I18n map="components.notarization_dialog.table.contract_address" />
									</Box>
								</TableCell>

								<TableCell sx={styles.headCellsStyle} align="center">
									<Box sx={styles.cellContent}>
										<I18n map="components.notarization_dialog.table.registration_tx" />
									</Box>
								</TableCell>

								<TableCell />
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.rows.map((file: AtmoboxFile) => {
								return (
									<TableRow key={`${file.id}-${file.fileMeta?.id}`}>
										<TableCell>
											<Box sx={styles.file}>
												<Typography>{file.fileMeta?.name}</Typography>
											</Box>
										</TableCell>

										<TableCell>
											<IconButton onClick={() => this.downloadFiles(file, false)}>
												<DownloadIcon />
											</IconButton>
										</TableCell>

										<TableCell align="center">
											<Chip
												label={I18n.translate(`components.notarization_chip.${file.notarizationStatus}`)}
												color={getNotarizationColor(file.notarizationStatus)}
												size="small"
												style={{ maxWidth: "105px" }}
											/>
										</TableCell>

										<TableCell align="center">
											{file.fileMeta?.transaction?.contractAddress ? (
												<Link>{this.getAddressToRender(file.fileMeta.transaction.contractAddress)}</Link>
											) : (
												<Typography>--</Typography>
											)}
										</TableCell>

										<TableCell align="center">
											{file.fileMeta?.transaction?.transactionId ? (
												<Link>{this.getAddressToRender(file.fileMeta.transaction.transactionId)}</Link>
											) : (
												<Typography>--</Typography>
											)}
										</TableCell>

										<TableCell align="center">
											<Button
												onClick={() => this.downloadFiles(file, true)}
												variant="outlined"
												style={{ color: "black", borderColor: "black" }}
												startIcon={<DownloadIcon />}>
												<I18n map="components.notarization_dialog.table.download_dependencies_button" />
											</Button>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			);
		}

		return this.state.rows.map((file: AtmoboxFile) => (
			<Container key={`${file.id}-${file.fileMeta?.id}`} sx={styles.mobileTableContainer}>
				<Box sx={styles.mobileCardHeader}>
					<Typography sx={styles.mobileCardHeaderTitle}>
						<I18n map="components.notarization_dialog.table.filename" />
					</Typography>

					<Box sx={styles.mobileCardHeaderContent} display="flex" flexDirection="row">
						<Typography sx={styles.mobileFileName}>{file.fileMeta?.name}</Typography>
						<IconButton onClick={() => this.downloadFiles(file, false)}>
							<DownloadIcon />
						</IconButton>
					</Box>
				</Box>

				<hr />

				<Box sx={styles.mobileCardContent}>
					<Box sx={styles.mobileCardContentTitles}>
						<Typography sx={styles.mobileCardContentTitle}>
							<I18n map="components.notarization_dialog.table.notarization_status" />
						</Typography>

						<Typography sx={styles.mobileCardContentTitle}>
							<I18n map="components.notarization_dialog.table.contract_address" />
						</Typography>

						<Typography sx={styles.mobileCardContentTitle}>
							<I18n map="components.notarization_dialog.table.registration_tx" />
						</Typography>
					</Box>

					<Box sx={styles.mobileCardContentDatas}>
						<Box sx={styles.mobileCardContentData}>
							<Chip
								label={I18n.translate(`components.notarization_chip.${file.notarizationStatus}`)}
								color={getNotarizationColor(file.notarizationStatus)}
								size="small"
								style={{ maxWidth: "105px" }}
							/>
						</Box>
						<Box sx={styles.mobileCardContentData}>
							{file.fileMeta?.transaction?.contractAddress ? (
								<Typography>
									<Link>{this.getAddressToRender(file.fileMeta.transaction.contractAddress)}</Link>
								</Typography>
							) : (
								<Typography>--</Typography>
							)}
						</Box>
						<Box sx={styles.mobileCardContentData}>
							{file.fileMeta?.transaction?.transactionId ? (
								<Typography>
									<Link>{this.getAddressToRender(file.fileMeta.transaction.transactionId)}</Link>
								</Typography>
							) : (
								<Typography>--</Typography>
							)}
						</Box>
					</Box>
				</Box>

				<hr />

				<Button
					onClick={() => this.downloadFiles(file, true)}
					variant="outlined"
					style={{ color: "black", borderColor: "black", marginBottom: theme.spacing(3) }}
					startIcon={<DownloadIcon />}>
					<I18n map="components.notarization_dialog.table.download_dependencies_button" />
				</Button>
			</Container>
		));
	}

	public override componentDidMount(): void {
		this.sortTable();
	}

	private async downloadFiles(file: AtmoboxFile, withDependencies: boolean) {
		if (!file.fileMeta) {
			return;
		}
		const blob = await FileApi.getInstance().downloadFiles(
			[{ id: file.id, type: file.fileMeta.type }],
			withDependencies,
		);

		const fileEndName = withDependencies ? "_dependencies.zip" : ".zip";
		const newfileName = file.fileMeta.name.replace(/\.[^.]*$/, fileEndName);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		FileDownloader.download(blob, newfileName);
	}

	private sortTable() {
		this.setState({
			tabOrder: this.state.tabOrder === "desc" ? "asc" : "desc",
			rows: this.state.rows.sort((a: AtmoboxFile, b: AtmoboxFile) => {
				const comparison = (a.fileMeta?.name || "").localeCompare(b.fileMeta?.name || "");
				return this.state.tabOrder === "asc" ? comparison : -comparison;
			}),
		});
	}

	private getAddressToRender(address: string) {
		return address.substring(0, 5) + "..." + address.substring(address.length - 4);
	}
}

export default function NotarisationTable(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}
