import { addHours } from "date-fns";
import { Atmobox, DCFile, NotarizationStatus } from "models/Atmobox";

export function getDCFilesMissingEntries(atmoboxes: Atmobox[]): DCFile[] {
	const dcFiles = atmoboxes.reduce((acc, atmobox) => {
		if (!atmobox.fileEpoches) {
			return acc;
		}
		acc = [
			...acc,
			...atmobox.fileEpoches.reduce((acc, fe) => {
				if (!fe.dcFile || !fe.dcFile.fileMeta) {
					return acc;
				}
				const dcEntries = fe.dcFile.dcEntries;
				if (!dcEntries) {
					return [...acc, fe.dcFile];
				}

				const sortedDCEntries = dcEntries.sort(
					(dce1, dce2) => new Date(dce1.date).getTime() - new Date(dce2.date).getTime(),
				);

				const firstEntry = sortedDCEntries[0];
				if (!firstEntry) {
					return [...acc, fe.dcFile];
				}

				let dateToMatch = new Date(firstEntry.date);
				const missingEntries = sortedDCEntries.filter((dce) => {
					const dceDate = new Date(dce.date);
					const isMatching = dateToMatch.toISOString() === dceDate.toISOString();

					if (isMatching) {
						dateToMatch = addHours(dateToMatch, 1);
					} else {
						dateToMatch = addHours(dceDate, 1);
					}
					return !isMatching;
				});

				if (missingEntries.length === 0) {
					return acc;
				}

				return [...acc, fe.dcFile];
			}, [] as DCFile[]),
		];
		return acc;
	}, [] as DCFile[]);
	return dcFiles;
}

export function getNotarizationStatus(atmoboxes: Atmobox[]): NotarizationStatus {
	const statuses: NotarizationStatus[] = atmoboxes.flatMap(
		(a) =>
			a.fileEpoches?.flatMap((fe) => {
				const f: NotarizationStatus[] = [];
				if (fe.rawFile) {
					f.push(fe.rawFile.notarizationStatus);
				}
				if (fe.qcFile) {
					f.push(fe.qcFile.notarizationStatus);
				}
				if (fe.calFile) {
					f.push(fe.calFile.notarizationStatus);
				}
				if (fe.dcFile) {
					f.push(fe.dcFile.notarizationStatus);
				}
				return f;
			}) || [],
	);

	const isFull = statuses.length > 0 && statuses.filter((f) => f === "full").length === statuses.length;
	if (isFull) {
		return "full";
	}

	const isNone = statuses.filter((f) => f === "none").length === statuses.length;
	if (isNone) {
		return "none";
	}

	return "partial";
}

export function getNotarizationColor(notarizationStatus: NotarizationStatus) {
	const colors: Record<NotarizationStatus, "primary" | "warning" | "info"> = {
		full: "primary",
		partial: "warning",
		none: "info",
	};
	return colors[notarizationStatus];
}
