import { SxProps, Theme } from "@mui/material";

export const content: SxProps<Theme> = (theme) => {
	return {
		...{
			width: "100%",
			height: "1000px",
			hr: {
				width: "95%",
				borderLeft: "1px solid",
				borderColor: "rgba(0, 0, 0, 0.2)",
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(4),
			},
		},
	};
};

export const cellContent: SxProps<Theme> = (theme) => {
	return {
		display: "flex",
		borderLeft: "2.5px solid",
		alignItems: "center",
		justifyContent: "center",
		borderColor: "rgba(0, 0, 0, 0.2)",
		paddingX: theme.spacing(2),
		width: "100%",
		height: "18px",
	};
};

export const headCellsStyle: SxProps<Theme> = {
	paddingX: 0,
	fontWeight: "bold",
};

export const file: SxProps<Theme> = {
	display: "flex",
	flexDirection: "row",
};

export const mobileTableContainer: SxProps<Theme> = (theme) => {
	return {
		marginTop: theme.spacing(3),
		border: "1px solid",
		borderColor: "rgba(0, 0, 0, 0.2)",
		borderRadius: "4px",
		maxWidth: "350px",
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignContent: "center",
	};
};

export const mobileCardHeader: SxProps<Theme> = (theme) => {
	return {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
		gap: theme.spacing(1),
	};
};

export const mobileCardHeaderTitle: SxProps<Theme> = {
	display: "flex",
	alignItems: "center",
	fontSize: "14px",
	fontWeight: "bold",
	color: "rgba(0, 0, 0, 0.87)",
};

export const mobileCardHeaderContent: SxProps<Theme> = {
	display: "flex",
	alignItems: "center",
};

export const mobileCardContent: SxProps<Theme> = {
	display: "flex",
	flexDirection: "row",
};

export const mobileCardContentTitles: SxProps<Theme> = (theme) => {
	return {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(3),
		marginBottom: theme.spacing(3),
	};
};

export const mobileCardContentTitle: SxProps<Theme> = (theme) => {
	return {
		marginRight: theme.spacing(4),
		display: "flex",
		alignItems: "center",
		height: "50px",
		fontSize: "14px",
		fontWeight: "bold",
		color: "rgba(0, 0, 0, 0.87)",
	};
};

export const mobileCardContentDatas: SxProps<Theme> = (theme) => {
	return {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(3),
		marginBottom: theme.spacing(3),
	};
};

export const mobileCardContentData: SxProps<Theme> = {
	display: "flex",
	alignItems: "center",
	height: "50px",
};

export const mobileFileName: SxProps<Theme> = {
	alignItems: "center",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignContent: "center",
	fontSize: "14px",
};

export const downloadDependencies: SxProps<Theme> = (theme) => {
	return {
		color: "black",
		borderColor: "black",
		marginBottom: theme.spacing(4),
	};
};
