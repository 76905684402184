import React, { Component } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

type IProps = {
	setFromDate: (newFromDate: Dayjs | null) => void;
	setToDate: (newToDate: Dayjs | null) => void;
	fromDate: Dayjs;
	toDate: Dayjs;
};

type IState = {
	fromDate: Dayjs | null;
	toDate: Dayjs | null;
};

export default class DatePicker extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			fromDate: this.props.fromDate,
			toDate: this.props.toDate,
		};
	}

	override render() {
		return (
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Stack direction="row" spacing={3} style={{ minWidth: "100%" }}>
					<MobileDatePicker
						inputFormat={"DD/MM/YYYY"}
						value={this.state.fromDate}
						label="From"
						onChange={this.handleFromChange.bind(this)}
						renderInput={(params) => (
							<TextField
								size="medium"
								{...params}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton edge="end">
												<CalendarTodayIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						)}
					/>
					<MobileDatePicker
						minDate={this.state.fromDate || undefined}
						inputFormat={"DD/MM/YYYY"}
						value={this.state.toDate}
						label="To"
						onChange={this.handleToChange.bind(this)}
						renderInput={(params) => (
							<TextField
								size="medium"
								{...params}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton edge="end">
												<CalendarTodayIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						)}
					/>
				</Stack>
			</LocalizationProvider>
		);
	}

	private removeTime(dateTime: Dayjs | null) {
		return dateTime ? dayjs(dateTime.format("YYYY-MM-DD")) : null;
	}

	private handleFromChange(newDate: Dayjs | null): void {
		const fromDate = this.removeTime(newDate);
		this.setState({ fromDate });
		this.props.setFromDate(fromDate);
	}

	private handleToChange(newDate: Dayjs | null): void {
		const toDate = this.removeTime(newDate);
		this.setState({ toDate });
		this.props.setToDate(toDate);
	}
}
