import React from "react";
import { ThemeProvider } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Config from "configs/Config";
import { theme } from "themes";

import GlobalContext, { defaultContext } from "./GlobalContext";
import MainRoutes from "./MainRoutes";

export default function Main() {
	const config = Config.getInstance();

	return (
		<GoogleOAuthProvider clientId={config.get().googleOauth.web.client_id}>
			<GlobalContext.Provider value={defaultContext}>
				<ThemeProvider theme={theme}>
					<MainRoutes />
				</ThemeProvider>
			</GlobalContext.Provider>
		</GoogleOAuthProvider>
	);
}
