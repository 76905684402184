import { Box, Container, Link, Typography } from "@mui/material";
import CEAIcon from "assets/images/icons/cea-logo-icon.svg";
import CEAImage from "assets/images/landingIcons/ceaIcon.svg";
import IcosCities from "assets/images/landingIcons/icosCities.png";
import IcosFRImage from "assets/images/landingIcons/icosFRIcon.svg";
import LetiImage from "assets/images/landingIcons/letiIcon.svg";
import ListImage from "assets/images/landingIcons/listIcon.svg";
import LsceImage from "assets/images/landingIcons/lsceIcon.svg";
import OriginEarth from "assets/images/landingIcons/originEarth.svg";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";

import GoogleLoginButton from "components/materials/GoogleLoginButton";
import HomeTopBar from "components/materials/HomeTopBar";
import I18n from "components/materials/I18n";
import BasePage from "components/pages/Base";
import DefaultTemplate from "components/pageTemplates/DefaultTemplate";

import * as styles from "./styles";

type IProps = {
	mediaQueries: MediaQueries;
};

class Component extends BasePage<IProps> {
	public override render(): React.ReactNode {
		return (
			<I18n
				map={"pages.home.title"}
				content={([title]) => (
					<DefaultTemplate title={title} padding={false}>
						<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
							<HomeTopBar />
							<Container sx={styles.content}>
								<Container>
									<Box sx={styles.title}>
										<img src={CEAIcon} style={{ width: "32px" }} />
										<Typography variant="h6">
											<I18n map="pages.home.explainTitle" />
										</Typography>
									</Box>
									<Box sx={styles.explainSubTitle}>
										<Typography color="#447745" variant="h3">
											<I18n map="pages.home.explainSubTitle1" />
										</Typography>
										<Typography variant="h3">
											<I18n map="pages.home.explainSubTitle2" />
										</Typography>
									</Box>
									<Typography sx={styles.explainText} variant="h6">
										<I18n map="pages.home.explainText" />
									</Typography>
									<Box sx={styles.signInButton}>
										<GoogleLoginButton />
									</Box>
								</Container>
								<Box sx={styles.bigLeaveImage(this.props.mediaQueries)} />
								<Container sx={styles.whoAreWeContainer(this.props.mediaQueries)}>
									<Box sx={styles.smallLeaveImage(this.props.mediaQueries)} />
									<Container sx={styles.whoAreWe(this.props.mediaQueries)}>
										<Typography color="#447745" variant="h4">
											<I18n map="pages.home.whoTitle" />
										</Typography>
										<Typography sx={styles.whoAreWeText} variant="subtitle1">
											<I18n map="pages.home.whoText" />
										</Typography>
										<Typography sx={styles.whoAreSubText} variant="body1">
											<I18n map="pages.home.whoSubText" />
										</Typography>

										<Box sx={styles.whoAreWeIcons(this.props.mediaQueries)}>
											<Link href="https://www.cea.fr/">
												<img src={CEAImage} style={{ width: "112px", height: "91.38px" }} />
											</Link>
											<Link href="https://www.leti-cea.com/cea-tech/leti/english">
												<img src={LetiImage} style={{ width: "112px", height: "91.38px" }} />
											</Link>
											<Link href="https://list.cea.fr/en/">
												<img src={ListImage} style={{ width: "112px", height: "91.38px" }} />
											</Link>
											<Link href="https://www.lsce.ipsl.fr">
												<img src={LsceImage} style={{ width: "112px", height: "91.38px" }} />
											</Link>
										</Box>
									</Container>
								</Container>
								<Box sx={styles.partners}>
									<Typography sx={styles.partnersTitle} variant="h4">
										<I18n map="pages.home.partnersTitle" />
									</Typography>
									<Typography sx={styles.partnersText} variant="subtitle1">
										<I18n map="pages.home.partnersText" />
										<br />
										<I18n map="pages.home.partnersText2" />
									</Typography>
									<Container sx={styles.partnersIcons(this.props.mediaQueries)}>
										<Link href="https://icos-france.fr/">
											<img src={IcosFRImage} style={{ width: "200px", height: "auto" }} />
										</Link>
										<Link href="https://www.icos-cp.eu/">
											<img src={IcosCities} style={{ width: "180px", height: "auto" }} />
										</Link>
										<Link href="https://www.origins.earth/">
											<img src={OriginEarth} style={{ width: "160px", height: "auto" }} />
										</Link>
									</Container>
								</Box>
								<Box sx={styles.footerLeavesImage(this.props.mediaQueries)} />
							</Container>
						</Box>
					</DefaultTemplate>
				)}
			/>
		);
	}
}

export default function Home() {
	const mediaQueries = useMediaQueries();

	return <Component {...{ mediaQueries }} />;
}
