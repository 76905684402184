import React from "react";
import { Logout } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { googleLogout } from "@react-oauth/google";
import UserStore from "stores/UserStore";

import I18n from "../I18n";

export default function GoogleLogoutButton() {
	const userStore = UserStore.getInstance();

	async function logout() {
		googleLogout();
		await userStore.signOut();
	}

	return <LogoutButton signOut={logout} />;
}

type IProps = { signOut: () => void };
type IState = Record<string, never>;

class LogoutButton extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
	}

	public override render(): React.ReactNode {
		return (
			<Button variant="outlined" onClick={this.props.signOut} color="info">
				<Logout />
				<Typography variant="body2" ml={2}>
					<I18n map={"components.dashboard_top_bar.logout_button"} />
				</Typography>
			</Button>
		);
	}
}
